import './Footer.css';

const Footer = (props) => {

return(
<footer id="footer1" className="footer " role="contentinfo">

    <div className="cont_w clearfix">


        <h2 className="heading_separator">
            Kontakt
        </h2>
        <div className="footer_col_inner">
            <div id="footerCol1">
                <img style={{width: '160px'}} alt="Radio Olsztyn" src="https://radioolsztyn.pl/wp-content/themes/ronew/img/ro_logo_n.png" />
            </div>

            <div className="">
                <div>Centrala: (89) 526 44 34</div>
                <div>Newsroom: (89) 651 08 48</div>
                <div>Sekretariat: (89) 651 08 12</div>
            </div>

            <div className="">
                <div>Radio Olsztyn S.A.</div>
                <div>ul. Radiowa 24</div>
                <div>10-206 OLSZTYN </div>
                <div>E-mail: radio@radioolsztyn.pl</div>
            </div>
        </div>




    </div>
    
    <div  id="footer_cookiesinfo" >
        <div className="cont_w">
            Nasz strona wykorzystuje ciasteczka "cookies". Sprawdź  <a  target="_blank"  href="https://radioolsztyn.pl/polityka-cookies">naszą politykę</a> <br/>
            <a target="_blank" href="https://archiwum.radioolsztyn.pl/media/docs/regulamin_archiwumro.pdf">Zobacz regulamin strony</a> &nbsp;
        </div>
    </div>
   
</footer>

    );
}

export default Footer;