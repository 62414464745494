import './Loading.css';
import {CircularProgress} from '@material-ui/core';

const Loading = (props) =>{
    
    return (
        <div id="Loading">
            <div id="LoadingInner">
                <CircularProgress color="inherit" />
                <div>{props.text}</div>
            </div>
        </div>
    );
    
}

export default Loading;

