import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {setStatus, setCurrentPodcastIndex} from '../../redux/playerSlice';

import './Player.css';
import ArrowImg from '../../resources/images/leftArrow.svg';
import PlayImg from '../../resources/images/play.svg';

//var current_time = 0;
var lastVolume = 0;

const Player = (props) =>{
    
    const dispatch = useDispatch();
    const player = useSelector( state => state.player );
    const playerPodasts = useSelector( state=>state.player.podcasts);
    const paths = useSelector( state=>state.cats.paths);
    const [volume, setVolume] = useState(100);
    const [muted, setMuted] = useState(false);
    const [showPlayerMobile, setShowPlayerMobile] = useState(false);
    


    // start & stop on store change
    useEffect(()=>{
        console.log('player.status', player.status);
        
        if( document.querySelector('#PlayerAudio') ){
            if( player.status === 'play' ){
                document.querySelector('#PlayerAudio').play();
            }else if( player.status === 'stop' ){
                document.querySelector('#PlayerAudio').pause();
            }
        }
        
    }, [player.status]);
    
    const play = () =>{
        if( player.soundURL ){
            dispatch( setStatus("play") );
        }
    }
    const stop = () =>{
        dispatch( setStatus("stop") );
    }
    const prev = () =>{
        
        
        if( playerPodasts && playerPodasts.length > 0 && player.currentPodcastIndex >= 0 ){
            
            if(player.currentPodcastIndex > 0 ){ 

                dispatch(  setCurrentPodcastIndex( player.currentPodcastIndex - 1 ) ); 
            }else{

                dispatch(  setCurrentPodcastIndex(playerPodasts.length - 1) );
            }
            
        }
    }
    const next = () =>{
        
        if( playerPodasts && playerPodasts.length > 0 && player.currentPodcastIndex >= 0 ){
            
            if(player.currentPodcastIndex < playerPodasts.length -1  ){ 
                dispatch(  setCurrentPodcastIndex( player.currentPodcastIndex + 1 ) ); 
            }else{
                dispatch( setCurrentPodcastIndex(0) );
            }
            
        }
    }
    
    const mute = () =>{
 
        if( !muted ){
            
            // mute
            lastVolume = volume;
            setVolume(0);
            setMuted(true);
            
        }else{
            
            // unmute
            setVolume(lastVolume);
            setMuted(false);
        }

    }
    
    
    const onTrackEnded = ()=>{
        console.log("onTrackEnded()");
        next();
    }
    
    
    // zmiana tracku
    const onTrackChange = () =>{
        
         console.log('onTrackChange() url',paths.podcast_sound_url + player.soundURL);
        
        if( paths.podcast_sound_url &&  player.soundURL){
            document.querySelector('#PlayerAudio').src = paths.podcast_sound_url + player.soundURL;
            document.querySelector('#PlayerAudio').play();
        }
        
    }

    useEffect(onTrackChange, [player.soundURL, paths]);
    
    
    
    // ustawienia progress bar
    const secondsToHms = (sec) => {
        
        if( sec > 0 ){
            var date = new Date(null);
            date.setSeconds(sec); // specify value for SECONDS 
            return date.toISOString().substr(14, 5);
        }else{
            return "00:00";
        }
    }
    
    const onTimeupdate = (ev)=>{
        
        console.log('onTimeupdate()');

        if( !ev.target.currentTime )
            return;

        //current_time = ev.target.currentTime;

        // aktualizujemy licznik
        //var _time_display = secondsToHms( ev.target.currentTime )+ " / " + secondsToHms(ev.target.duration);
        document.querySelector("#PlayerTime").innerHTML = secondsToHms( ev.target.currentTime );
        document.querySelector("#PlayerTimeTotal").innerHTML = secondsToHms(ev.target.duration);

        // aktualizujemy progresBar
        var _curr_progr = parseFloat( ev.target.currentTime / ev.target.duration );


        if( _curr_progr > 0 )  
            document.querySelector("#PlayerProgress").style.width =  (_curr_progr * 100) + "%";

    }
    
    
    // przesuwanie 
     const onSeek = (ev) => {
        console.log("onSeek()");

        let audio = document.querySelector('#PlayerAudio');
        var clickRect = ev.target.getBoundingClientRect();

        var x = ev.clientX - clickRect.left;
        var progress = x / ev.target.offsetWidth;
  
        
        audio.currentTime = progress * audio.duration;
       

    }

    useEffect( () =>{

        if( document.querySelector('#PlayerProgress') ){
            
            console.log('add ev');

            document.querySelector('#PlayerAudio').addEventListener('timeupdate', onTimeupdate);
            document.querySelector('#PlayerProgress').addEventListener('click', onSeek);
        
        }
 
    } , [onSeek]);


    // zmiana dzwieku
    useEffect(()=>{
            
        if(document.querySelector('#PlayerAudio'))
            document.querySelector('#PlayerAudio').volume = volume / 100;
        
    },[volume]);
    
    const onVolChange = (ev) =>{
        //console.log('val', ev.target.value);
        setVolume(ev.target.value);
 
    }
    
    if( player && player.soundURL ){
        
        return (
        <div id="PlayerOuter">

            
            <a id="PlayerCloseArrowBt" className={showPlayerMobile?'open':'closed'} onClick={ () => { setShowPlayerMobile( !showPlayerMobile ) } } >
                <img alt={showPlayerMobile?'Ukryj odtwarzacz':'Pokaż odtwarzacz'}  src={showPlayerMobile?ArrowImg:PlayImg} />
            </a>
                
            <div id="Player" className={showPlayerMobile?'open':'closed'} >
                
                <audio id="PlayerAudio" onEnded={ () => { onTrackEnded() }} onTimeUpdate={ (ev)=>{ onTimeupdate(ev) }} ></audio> 
                <div id="PlayerInner">
                
                
                    <div id="PlayerImageCont">
                        {player && player.imageURL?
                            <img alt="Podcast" id="PlayerImage" src={player.imageURL} />
                            :''
                        }
                    </div>


                    <div id="PlayerTrackTitle">
                        <div>
                            {player && player.trackTitle}
                        </div>
                        
                    </div>

                    <div id="PlayerControls">

                        <div id="PlayerControlsButtons">
                            <button id="Prev" onClick={()=>{ prev(); }} ></button>
                            {player && player.status === "stop" ?
                                <button id="Play" onClick={()=>{ play(); }} ></button>
                            : player && (  player.status === "play") ? 
                                <button id="Pause" onClick={()=>{ stop(); }} ></button>
                            :null
                            }
                            <button id="Next" onClick={()=>{ next(); }} ></button>
                        </div>
                        <div id="PlayerTimeOuter">
                            <div id="PlayerTime" className="playerTime">0:00</div>
                            <div id="PlayerControlsTrack">
                                <div id="PlayerProgress" ></div>
                                <div id="PlayerProgressSeek" onClick={(ev) =>{ onSeek(ev)}} ></div>
                            </div>
                            <div id="PlayerTimeTotal" className="playerTime">0:00</div>
                        </div>
                        <div id="PlayerSoundOuter">
                            <button id="Mute" className={muted?'muted':'unmuted'}  onClick={()=>{ mute(); }} ></button>
                            <div id="PlayerControlsSound"> 
                                <input id="PlayerVolume" type="range" min="0" max="100" value={volume} step="1" onChange={(ev)=>{onVolChange(ev)}} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        );
    }else{
        return(<span/>);
    }
    
}

export default Player; 