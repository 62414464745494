import {useEffect, useState} from 'react';
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import {setCurrentPodcastIndex, setPodcasts, setStatus, setPodcastImg} from '../../redux/playerSlice';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Parser from 'react-html-parser';
import Modal from '@material-ui/core/Modal';
import Loading from '../Common/Loading';
import {API} from '../../services/api';
import './PodcastList.css';


const PodcastList = (props)=>{
    
    const dispatch = useDispatch();
    const playerPodcasts = useSelector( state=>state.player.podcasts);
    const player = useSelector(state=>state.player);
    const bcasts =  useSelector(state=>state.bcasts.data);
    const paths = useSelector( state=>state.cats.paths);
    const [storyModalShow, setStoryModalShow] = useState(false);
    const [storyModalHTML, setStoryModalHTML] = useState('');


    // zmiana utworu - pobieramy obrazek audycji i dajemy go do playera 
   useEffect(()=>{
       
       
       
       if( player.soundURL ){
            API.getPodcastImage(player.soundURL).then( res=>{

                 if( res.success && res.imageURL ){
                    if( res.type === 'broadcast'){
                         dispatch( setPodcastImg(paths.bcast_image_url + res.imageURL));
                    }else if(res.type === 'category'){
                         dispatch( setPodcastImg(paths.cat_image_url + res.imageURL));
                    }
                 }else{
                     dispatch( setPodcastImg(null));
                 }

            });
        }
       
   },[player.soundURL, bcasts, dispatch]);
   
   // zmiana wyszukiwania
   useEffect(()=>{
       
       // aktualizujemy index utworu
       if( props.list )
            dispatch( setPodcasts(props.list) );
   }, [props.list, dispatch]);
   
 
    const play = (idx) =>{

         if( props.list && props.list.length > 0){
             
             // wysyłamy do store/player listę podcastów
            if( props.list !== playerPodcasts ) {       
               dispatch( setPodcasts(props.list) ); 
            }
            
             // wysyłamy do store/player akt index
            dispatch( setCurrentPodcastIndex(idx) );
            
             // jeżeli klik na aktualnie odtwarzanym - zatrzymujemy - wysyłamy do store/player status
             if(player && player.status === 'play' && player.currentPodcastIndex === idx ){
                 dispatch( setStatus('stop') );
             }
         }
    }
    
    const storyShow = (id_dac)=>{
        
        console.log('storyShow', id_dac);
        
        // pobieramy story
        API.getPreviewStory(id_dac)
            .then( res => res.text() )
            .then((res)=>{

            res = res.replace('onclick', 'onClick');

            // pokazujemy modal
            setStoryModalHTML(res);
            setStoryModalShow(true);
            
            //
            document.querySelector('.printButton').addEventListener('click', ()=>{ window.print()});
            document.querySelector('.closeButton').addEventListener('click', ()=>{setStoryModalShow(false)});

        });

    }
   
    const podcastDescrMoreClick = (ev) =>{
        
        // rozwijamy pole tekstowe
        ev.target.closest('.podcastItemDescr').querySelector('.podcastItemDescrInner').style.height = "auto";
        
        // chowamy przycisk
        ev.target.style.display = 'none';
        
    }

    const calcDescrHeight = (el) =>{

        if( el && el.offsetHeight < 100 ){
            el.closest('.podcastItemDescr').querySelector('.podcastDescrMoreBt').style.display="none";
        }

    }
    
    
    const accordionStyle = {
        background:'rgba(0,0,0,0.4)',
        color:'#fff'
    }
    
    


    if( !props.list){
        return (<Loading text ="Wczytuje" />);
    }

 
    return(
      
        <div>
            {props.list && props.list.map( (item, idx)=>
                <Accordion  style={accordionStyle} key={item.id} className="podcastItem">
                        <AccordionSummary  className="podcastItemHeader" expandIcon={<ExpandMoreIcon style={{color:'#fff'}} />}>
                            <button className={(player &&  player.currentPodcastIndex === idx && player.status === 'play' )? 'PI_play playing':'PI_play'}   onClick={(event) => { event.stopPropagation(); play(idx);  }} ></button>
                            <a className="podcastStoryLink" onClick={(event)=>{ event.stopPropagation();  play(idx); /* storyShow(item.id_dac); */}} >
                                {item.name}
                                {item.author?
                                    <span className='podcastAuthor' >{', autor: ' + item.author}</span>
                                :''}
                                {item.timespan?
                                    <span className='podcastTimespan' >{', czas: ' + item.timespan }</span>
                                :''}
                            </a>
                        </AccordionSummary>
                        <AccordionDetails className="podcastItemDescr">
                            <div className="podcastItemDescrInner" >
                                <p ref={calcDescrHeight}>
                                    {item.descr}
                                </p>
                            </div>

                            <div className="podcastyButtonsCont" >
                                <a className='podcastDescrMoreBt podcastBt' onClick={(ev)=>{podcastDescrMoreClick(ev)}} >Więcej</a>
                                <a className='podcastBt' onClick={(event)=>{ storyShow(item.id_dac); }} >Pokaż szczegóły</a>
                            </div>

                        </AccordionDetails>

                </Accordion>

            )}
            
            {props.list && props.list.length === 0?
                <div id="noRes">Brak wyników</div>
            :''}

            <div id="PodcastsPages">

                    <div>
                        { props.pages && props.pages.length > 1?
                       <div style={{padding:'10px'}}>Strona</div>
                       :''}

                        { props.pages && props.pages.length > 1 && props.pages.map( (i)=>
                            <span key={i}>
                            { ( i <= 10 || i > ( props.pages.length - 5)) ?
                                <a className={"podcastPage " + ( props.page === i? 'selected':'')} onClick={() =>{ props.setPage(i) } }  >{i}</a>
                            : (i === 11) ?
                                <a className="podcastPageNotSelectable"   key={i} > .. </a>
                            :''
                            }
                            </span>
                        )}
                    </div>

                    { props.pages && props.pages.length > 10? 
                    <div>
                        <select className='selectPodcastPage' value={props.page} onChange={ (ev)=>{ props.setPage(ev.target.value)}}  >
                            {props.pages.map((i)=>
                                <option key={i} value={i}>{'Strona '+i}</option>
                            )}
                        </select>                     
                    </div>
                    :''}
            </div>


            <Modal open={storyModalShow} onClose={()=>{setStoryModalShow(false)} } >
                <div className="storyModalInner ">

                    {Parser(storyModalHTML)}

                </div>
            </Modal>
        
        </div>
   
    );
    
}

export default PodcastList;

