import './StartPage.css';
import CategoryList from '../Category/CategoryList';
import PodcastList from '../Podcast/PodcastList';
import {useSelector} from 'react-redux';


const StartPage = (props) =>{
    
    const cats = useSelector( state => state.cats );


    return(
        <div id="startPage">
            
           
            
            <h2>KATEGORIE</h2>
            <section>
                <CategoryList list={cats.data} imagePath={cats.paths.cat_image_url} />
            </section>
            
            <h2 id="OstatnioDodaneHeader">OSTATNIO DODANE</h2>
            <section>
                <PodcastList list={cats && cats.lastPodcasts}  />
            </section>
            
        </div>    
    );
    
}

export default StartPage;
