import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
//import {useSelector, useDispatch} from 'react-redux';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Player from '../Player/Player';
import StartPage from '../Start/StartPage';
import CategoryPage from '../Category/CategoryPage';
import BroadcastPage from '../Broadcast/BroadcastPage';
import SearchPage from '../Search/SearchPage';

import './App.css';

function App() {
  return (
          
    <Router>
        <div id="App" >
            <Header/>
            
            <div id="ContentPage" className='cont_w'>
                <Switch>
                    <Route path="/category/:id" component={CategoryPage} />
                    <Route path="/broadcast/:id" component={BroadcastPage} />
                    <Route path="/search" component={SearchPage} />
                    <Route path="/"  component={StartPage} />
                </Switch>
            </div>

            <Footer />
            
            <Player />
        </div>
    </Router>
        
  );
}

export default App;
