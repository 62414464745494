import {useEffect, useState} from 'react';
import {API} from '../../services/api';
import './PodcastList.css';
import PodcastList from './PodcastList';

var onSearchTextChangeTimeout = 0;

const PodcastsBroadcast = (props)=>{

    const perPage = 10;

    const [page, setPage] = useState(1);
    const [pages, setPages] = useState([]);
    const [list, setList] = useState([]);
    const [showHeader, setShowHeader] = useState(false);

 
    // init - zmiana props
    useEffect( ()=>{

        // opcje sortowania, jeżeli audycja posiada podcasty
        if( props.podcastsCount &&  props.podcastsCount > 0 )
             setShowHeader(true);

         loadPodcasts();

   }, [props]);

   
   // zmiana strony
   useEffect(()=>{
       
       loadPodcasts();
       
   }, [page]);
 
 
    const loadPodcasts = ( resetPage = false ) =>{
        
        if( ! props.broadcastId )
            return;

        if( resetPage )
            setPage(1);
        
        setList(false);

        var nameFilter = document.querySelector('#PodcastNameFilter')? document.querySelector('#PodcastNameFilter').value:"";
        var sortOrder = document.querySelector('#PodcastSort')? document.querySelector('#PodcastSort').value:"default";

        API.getPodcasts( props.broadcastId, sortOrder, nameFilter, page  ).then( (res)=>{
            
            // lista podcastow
            if( res && res.podcasts ){
                setList(res.podcasts);
            }else{
                console.log('error: ', res);
            }
            
            // liczba stron
            if(res &&  res.count  ){
              createPagesFromCount(res.count);
            }

        });
        
    }
    
    const createPagesFromCount = (res_count)=>{


        // liczymy i ustawiamy stronicowanie
        var pagesCount = Math.ceil( parseInt(res_count) / perPage );
        var _pages = [];
        for( var i = 1; i <=pagesCount; i++ ){
            _pages.push(i);
        }
        setPages(_pages);
    }
    
    
    const onSearchTextChange = ()=>{
        
        // czyscimy poprzedni
        window.clearTimeout(onSearchTextChangeTimeout)
        
        // ustawiamy timeout
        onSearchTextChangeTimeout = window.setTimeout(()=>{
            loadPodcasts(true);
        }, 400);
        
        
    }

 
    return(
      
        <div>

            <section id="PodcastList">
            
                {showHeader ?
                <div id="PodcastListHeader">

                    <h2>Podcasty ({props.podcastsCount})</h2>

                    
                        <div id="SortFilterCont">
                            <input id="PodcastNameFilter" placeholder="filtruj wg nazwy" type="text" onChange={ ()=>{ onSearchTextChange() }}  />
                            <select id="PodcastSort" onChange={ ()=>{ loadPodcasts( true ) }} >
                                <option value="default">Domyślne sortowanie</option>
                                <option value="newest">Od najnowszych</option>
                                <option value="oldest">Od najstarszych</option>
                                <option value="az">Alfabetycznie</option>
                            </select>
                        </div>
                    
                </div>
                :''}
  
                <PodcastList list={list} pages={pages} page={page} setPage={ (i)=>{ setPage(i) } } />

            </section>
            
        
        </div>
   
    );
    
}

export default PodcastsBroadcast;

