import './BroadcastPage.css';
import { useSelector, useDispatch } from 'react-redux';
import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {loadCats} from '../../redux/catsSlice';
import {loadBcast} from '../../redux/bcastSlice';
import PodcastsBroadcast from '../Podcast/PodcastsBroadcast';
import Parser from 'react-html-parser';


const BroadcastPage = (props) =>{
    
    const dispatch = useDispatch();
    const cats = useSelector( state => state.cats.data );
    const broadcast = useSelector( state => state.bcasts.data.find( item => item.id === props.match.params.id) );
    const paths = useSelector( state => state.cats.paths);

    
    useEffect( () =>{
        
        window.scrollTo(0,0);
        
        if( !broadcast || !broadcast.descr) {
            dispatch( loadBcast(props.match.params.id) );
        }

    }, [props, dispatch, cats, broadcast]);
    
    
    return (
            <div id="BroadcastPage">
                <div id="BreadCrumb">
                    <Link id="BreadCrumbStart" to="/"></Link>
                    »{broadcast && broadcast.name_parent && broadcast.id_parent ?
                        <Link to={"/category/"+ broadcast.id_parent}>Kategoria: {broadcast.name_parent }</Link>
                    :null}
                    » Audycja: {broadcast && broadcast.name}
                </div>


                <h1>{broadcast && broadcast.name}</h1>
                
                <section id="catTop">
                    {broadcast && broadcast.imageURL?
                        <div className="catImageOuter">
                            { ( broadcast.imageURL.indexOf('mp4') > -1 || broadcast.imageURL.indexOf('.avi') > -1 || broadcast.imageURL.indexOf('.mpg') > -1 ) ?
                                <video id="BroadcastVideo" style={{maxWidth:'100%'}} controls="true" src={broadcast && (paths.bcast_image_url + broadcast.imageURL) }></video>
                                :
                                <img className="catImage" alt="Kategoria" src={broadcast && (paths.bcast_image_url + broadcast.imageURL) } />
                            }
                        </div>
                        :''}

                    <div className="descrOuter" >
                        {Parser(broadcast && broadcast.descr)}
                    </div> 

                </section>
                
                <section id="bcPodcasts">
                    <PodcastsBroadcast broadcastId={broadcast && broadcast.id} podcastsCount={broadcast && broadcast.podcastsCount} />
                </section>
                
                
            </div>
          
    );
    
    
    
}

export default BroadcastPage;