import {config} from'./config';

const request = {
    get: (url, defaultBaseUrl) =>{
        
        var baseUrl = defaultBaseUrl || config.apiURL;
    
        return fetch( baseUrl + url )
            .then( res => res.json())
            .catch(error => {
                alert('Błąd komunikacji: ' + error);
           })
    },
    
    getRaw: (url) =>{
    
        return fetch( config.baseURL + url )
            .catch(error => {
                alert('Błąd komunikacji: ' + error);
           })
    },
           
    post: (url, body, extraOptions = {})=>{
        
        // ustawiamy opcje
        let options = {method:'post', body:body};
        options = Object.assign(options, extraOptions);
        
        // wykonujemy żądanie
        return fetch( config.apiURL + url, options)
            .then( res => res.json())
            .catch(error => {
                alert('Błąd komunikacji: ' + error);
           })
       }
};

const API = {

    
    getDataTree: () =>
        request.get('/data_tree'),
    
    getBroadcastById: id =>
        request.get('/broadcast/' + id),
        
    getPodcastImage: id =>
        request.get('/podcastImage/' + id),
        
    search: queryParams =>
        request.get('/search?' + queryParams.toString()),
        
    getPreviewStory: id_dac=>
        request.getRaw('/preview/story_show_podcast/' + id_dac),
        
    getPodcasts: (broadastId, sortOrder="default", nameFilter = false, page = 1 ) =>{
        
        var url = "/podcasts";
        
        // id audycji
        url = url + "?broadcastId="+broadastId;
        
        // sortowanie
        url = url + "&sortOrder="+sortOrder;
        
        // strona
        url = url + "&page="+page;
        
        
        // filtr wg nazwy
        if( nameFilter ){
            url = url + "&nameFilter="+nameFilter;
        }

        
        return request.get(url);
    }
};



export {API};