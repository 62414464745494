import './Search.css';
import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import PodcastList from '../Podcast/PodcastList';
import BroadcastList from '../Broadcast/BroadcastList';
import {API} from '../../services/api';

const SearchPage = (props) =>{

    const perPage = 10;
    
    const [res, setRes] = useState({});
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchParams, setSearchParams] = useState('');
    
    const paths = useSelector( state => state.cats.paths);
    
    const fetchSearch = async() => {
        
        
        setRes({});
        
        // pobieramy params z url
        var queryParams = new URLSearchParams(window.location.search);
        
        //dodajemy strone
        queryParams.set('page', page);
        
        // wykonujemy żądanie
        const _res = await API.search(queryParams);
        
        // ustawiamy wynik
        setRes(_res);
        
        // liczba stron
        if(_res &&  _res.podcastsCount  ){
          createPodastPagesFromCount(_res.podcastsCount);
        }
    }
    
    
    const createPodastPagesFromCount = (res_count)=>{

        // ustawiamy liczbe wynikow
        setTotalCount( parseInt(res_count) );

        // liczymy i ustawiamy stronicowanie
        var pagesCount = Math.ceil( parseInt(res_count) / perPage );
        var _pages = [];
        for( var i = 1; i <=pagesCount; i++ ){
            _pages.push(i);
        }
        setPages(_pages);
    }

   
    // zmiana strony
   useEffect(()=>{
       
       // reset strony przy zmianie parametrów wyszukiwania
       if( searchParams !== window.location.search ){
            setPage(1);
        }
      
       fetchSearch();
       
       // zapisujemy je w stare
       setSearchParams(window.location.search);
       
   }, [window.location.search, page]);
   
    
    return(
        <div id="startPage">

            <div id="BreadCrumb">
                <Link id="BreadCrumbStart" to="/"></Link>
                » Wyszukiwanie
            </div>

            {res && res.broadcasts?            
                <section id="catBroadcasts">
                    <h2>Znalezione Audycje: { res.broadcasts.length}</h2>
                    <BroadcastList list={res && res.broadcasts} imagePath={paths.bcast_image_url} />
                </section>
            :''}
                
            <section>
                <h2>Znalezione Podcasty: {totalCount}</h2>
                <PodcastList list={res.podcasts} pages={pages} page={page} setPage={ (i)=>{ setPage(i) } } />
            </section>
        </div>    
    );
    
}

export default SearchPage;
