import './CategoryPage.css';
import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Parser from 'react-html-parser'; 
import BroadcastList from '../Broadcast/BroadcastList';
import {loadCats} from '../../redux/catsSlice';


const CategoryPage = (props)=>{
    
    const dispatch = useDispatch();
    const catData = useSelector( state => state.cats.data.find( (cat)=> cat.id === props.match.params.id ) );
    const paths = useSelector( state => state.cats.paths );
    
    useEffect(()=>{
        
        window.scrollTo(0,0);
        
        
    }, [props, dispatch, catData]);
    
    
    return(
        <div id="CategoryPage">
            <div id="BreadCrumb">
                <Link id="BreadCrumbStart" to="/"></Link>
                » Kategoria: {catData && catData.name}
            </div>
            
            <h1>{catData && catData.name}</h1>
            <section id="catTop">
                {catData && catData.imageURL?
                    <div className="catImageOuter">
                        <img className="catImage" alt="Kategoria" src={catData && (paths.cat_image_url + catData.imageURL) } />
                    </div>
                    :''}
                
                <div className="descrOuter" >
                    {Parser(catData && catData.descr)}
                </div>
            
            </section>
            
            {catData && catData.broadcasts && catData.broadcasts.length > 0 ?
                <section id="catBroadcasts">
                    <h2>Audycje</h2>
                    <BroadcastList list={catData && catData.broadcasts} imagePath={paths.bcast_image_url} />
                </section>
            :''}

        </div>
    );
    
    
}

export default CategoryPage;