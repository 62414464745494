import {configureStore} from '@reduxjs/toolkit';
import catsReducer from './redux/catsSlice';
import bcastReducer from './redux/bcastSlice';
import playerReducer from './redux/playerSlice';

export default configureStore({
    reducer: {
        cats: catsReducer,
        bcasts: bcastReducer,
        player:playerReducer
    }
})
