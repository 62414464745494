import '../Broadcast/BroadcastList.css';
import Loading from '../Common/Loading';
import {Link} from 'react-router-dom';

const BroadcastList = (props) =>{
    
    if( !props.list  ){

        // preloader
        return (<Loading text ="Wczytuje" />);

    }else{
        
        // lista boxów
        return(
            <ul id="BroadcastList">
                {props.list && props.list.map( (bc, idx)=>
                    <li key={bc.id}>
                        <Link className="broadcastBox"  style={{background:"url('"+props.imagePath + bc.imageURL +"') no-repeat center center", backgroundSize:'cover'}} to={'/broadcast/' + bc.id }>
                            <div className="catBoxInner">
                                <h4  className="broadcastBoxTitle">{bc.name}</h4>
                                <div className="broadcastBoxBcCount">{bc.podcastsCount} { bc.podcastsCount ? bc.podcastsCount == 1 ?  ' podcast': ( bc.podcastsCount < 9 || bc.podcastsCount > 14) && ( bc.podcastsCount % 10 === 2 || bc.podcastsCount % 10 === 3 || bc.podcastsCount % 10 === 4) ? ' podcasty' : ' podcastów' : ''   }</div>
                            </div>

                        </Link>
                    </li>
                )}
            </ul>  
        ); 
    }
    
    
    
    
}

export default BroadcastList;
