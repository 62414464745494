import './CategoryList.css';
import Loading from '../Common/Loading';
import {Link} from 'react-router-dom';

const CategoryList = (props) =>{
    
    if( !props.list || props.list.length === 0 ){

        // preloader
        return (<Loading text ="Wczytuje" />);

    }else{
        
        // lista boxów
        return(
            <ul id="CategoryList">
                {props.list && props.list.map( (el, idx)=>
                    <li key={el.id}>
                        <Link className="categoryBox"  style={{background:"url('"+props.imagePath+el.imageURL+"') no-repeat center center", backgroundSize:'cover'}} to={'/category/' + el.id }>
                            <div className="catBoxInner">
                                <h4  className="categoryBoxTitle">{el.name}</h4>
                                <div className="categoryBoxBcCount">{el.broadcasts.length} {el.broadcasts.length % 10 === 1? ' Audycja': ( el.broadcasts.length < 9 || el.broadcasts.length > 14)  && ( el.broadcasts.length % 10 === 2 || el.broadcasts.length % 10 === 3 || el.broadcasts.length % 10 === 4 ) ? ' Audycje' : ' Audycji'   }</div>
                                <div className="categoryBoxPodcastCount">{el.podcastsTotal} Podcastów</div>
                            </div>

                        </Link>
                    </li>
                )}
            </ul>  
        ); 
    }
    

}

export default CategoryList;
