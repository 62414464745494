import {createSlice} from '@reduxjs/toolkit';


const playerSlice = createSlice({
    name:'player',
    initialState:{
        status:"stop",
        soundURL:"",
        trackTitle:"",
        imageURL:"",
        currentPodcastIndex:null,
        podcasts:[]
    },
    reducers:{

        
        setStatus:(state, action) => {
            state.status = action.payload;
        },
        setCurrentPodcastIndex:(state, action) =>{
            
            const idx = action.payload;
            console.log('setCurrentPodcastIndex', idx);
            
            state.currentPodcastIndex = idx;
            const podcast = state.podcasts[idx];
            if(podcast){
                state.status = "play";
                state.soundURL = podcast.soundURL;
                state.trackTitle = podcast.name;

            }
        },
        

        
        setPodcastImg:(state, action) =>{
            state.imageURL = action.payload;
        },
        
        setPodcasts:(state, action) =>{
            console.log('setPodcasts');
            
            state.podcasts = action.payload;
            
            // probujemy zaktualizowac index
            if(state.soundURL){
                var newIdx = state.podcasts.findIndex( el=> el.soundURL === state.soundURL );

                if( newIdx > -1){
                    console.log('setPodcasts newIdx found', newIdx);

                    state.currentPodcastIndex = newIdx;
                    const podcast = state.podcasts[newIdx];
                    if(podcast){
                        state.status = "play";
                        state.soundURL = podcast.soundURL;
                        state.trackTitle = podcast.name;

                    }

                }else{
                    state.currentPodcastIndex = null;
                }
                    
            }
            
        }
    }
});

export const {setStatus, setCurrentPodcastIndex, setPodcasts, setPodcastImg} = playerSlice.actions;


export default playerSlice.reducer;