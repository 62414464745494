import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {API} from '../services/api'

export const loadBcast = createAsyncThunk('bcast/load', async (id) =>{
    
    const broadcast = await API.getBroadcastById(id);
    
    return broadcast;
});



const bcastSlice = createSlice({
    name:'broadcast',
    initialState:{
        data:[],
        status:''
    },
    reducers:{},
    extraReducers:{
        [loadBcast.pending]:(state, action)=>{
            state.status = 'loading';
        },
        [loadBcast.rejected]:(state, action)=>{
            state.status = 'failed';
        },
        [loadBcast.fulfilled]:(state, action)=>{
            
            state.status = 'success';
            state.data.push( action.payload.broadcast );
            
            
        }
    }
});


export default bcastSlice.reducer;
