import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {API} from '../services/api';

export const loadCats = createAsyncThunk('cats/load', async ()=>{
    
    const res = await API.getDataTree();
    return res;
    
});


const catsSlice = createSlice({
    name:'catTree',
    initialState:{status:'', data:[], paths:{}, lastPodcasts:[], broadcasts:[]},
    reducers:{},
    extraReducers: {
        
        [loadCats.pending]: (state, action) =>{
            state.status = "loading";
        },
        
        [loadCats.fulfilled]: (state, action) =>{
            state.status = "success";
            state.data = action.payload.categories;
            state.broadcasts = action.payload.broadcasts;
            state.paths = action.payload.paths;
            state.lastPodcasts = action.payload.lastPodcasts;
        },
        
        [loadCats.rejected]: (state, action) =>{
            state.status = 'failed';
        }
        

    }
    
    
    
});




export default catsSlice.reducer;