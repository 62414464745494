import './Header.css';
import Logo from '../../resources/images/logo.png';
import {Link, useHistory} from 'react-router-dom';
import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import {loadCats} from '../../redux/catsSlice';

const Header = (props) =>{
    
    
    const [term, setTerm] = useState('');
    const [delVisible, setDelVisible] = useState(false);
    const [detailedSearch, setDetailedSearch] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const broadcasts = useSelector( (state)=> state.cats.broadcasts);
    
    useEffect(()=>{
        
        var searchParams = new URLSearchParams( window.location.search ); 
       
        if( searchParams.get('term') ){
            const _term = searchParams.get('term');
            setTerm(_term);
            setDelVisible(true);
        }
        
        if( !broadcasts || broadcasts.length === 0 ){
            dispatch( loadCats() );
        }
        
  
    },[window.location.pathname, dispatch, broadcasts]);
    
    
    const onSearchSubmit = (ev) =>{
        
        
        
        ev.preventDefault();
        
        // pobieramy dane formularza
        var formData = new FormData(ev.target);
        
        // w trybie prostym pokazujemuy przyisk usuwania frazy
        if( ! formData.get('detailed'))
            setDelVisible(true);
        
        
        history.push('/search?' + new URLSearchParams(formData).toString() );
        
    }
    
    const clear = () =>{
        
        // czyscimy pole tx
        setTerm('');
        
        // chowamy przycisk
        setDelVisible(false);
        
        // wracamy do gl.
        history.push('/');
    }

    return(
        <header className='cont_w'>
            <div id="HeaderContent">
            
                <h1 id="HeaderTitle">PODCASTY</h1>
                <p id="HeaderText">
                    Słuchaj kiedy chcesz! <br/>
                    Archiwalne audycje Radia Olsztyn, których możesz słuchać w dowolnym momencie!
                </p>
                <Link id="HeaderLogoLink" to="/" >
                    <img id="HeaderLogo" alt="Radio Olsztyn Logo" src={Logo}  />
                </Link>
                
            </div>
            
            <div id="MainNavOuter">
                <form name="searchForm" id="searchForm" onSubmit={ (ev) => {onSearchSubmit(ev)}} >
                    <nav id="MainNav">
                        <ul>
                            <li><Link id="HomeLink" to="/">Strona Główna</Link></li>
                            <li><a id="RadioLink" href="https://radioolsztyn.pl/">RadioOlsztyn.pl</a></li>
                        </ul>
                    </nav>

                    {detailedSearch?
                        <div className="searchDetails">
                            <label htmlFor="wyszukaj" >Szukana fraza</label>
                        </div>

                    :''}
                    <div id="SearchOuter" className={detailedSearch?'':'mt30' } >

                        <input id="SearchText" value={term} onChange={ (ev) =>{ setTerm( ev.target.value ) }}  type="text" name="term"  />

                        {!detailedSearch?    
                            <button id="SearchBt" type="submit"></button>
                        :''}

                        {delVisible && !detailedSearch ?
                            <button id="ClearBt" onClick={ ()=>{ clear()  }}> x </button>
                            :null
                        }

                        { (term.length > 0 && term.length <= 2) ? 
                            <div id="SearchWarning">Fraza powinna zawierać min. 2 znaki</div> 
                        :null
                        }                
                    </div>


                    {detailedSearch?
                        
                        <div id="SearchDetailsPanel" className="searchDetails">
                            <input type="hidden" name="detailed" value="1" />
                            <div className="szukaj_segment mtb10">
                                <label htmlFor="autor">Autor audycji</label>
                                <input type="text" id="author" autoComplete="off" className="dib " placeholder="Wpisz imię lub nazwisko autora" name="author"/>  
                            </div>
                            
                            <div className="szukaj_segment mtb10">
                                <label htmlFor="participant">Rozmówca</label>
                                <input type="text" id="participant" autoComplete="off" className="dib " name="participant"/>  
                            </div>

                            <div className="szukaj_segment mtb10">
                                <label htmlFor="keywords">Słowa kluczowe</label>
                                <input type="text" id="keywords" autoComplete="off" className="dib " name="keywords"/>  
                            </div>

                            <div className="szukaj_segment mtb10">
                                <label htmlFor="place">Miejscowość</label>
                                <input type="text" id="place" autoComplete="off" className="dib " name="place"/>  
                            </div>

                            <div className="szukaj_segment mtb10">
                                <label htmlFor="audycja">Audycja</label>
                                <select autoComplete="off" name="id_broadcasts"  id="id_broadcasts"  onChange={()=>{ }}>
                                    <option value="0">-- wszystkie audycje --</option>
                                    {broadcasts && broadcasts.map( (bc, idx)=>
                                        <option key={idx} value={bc.id} > {bc.name} </option>
                                    )}
                                </select>
                            </div>

                            <div className="szukaj_segment mtb10 ">
                                    <label >Data nagrania</label>
                                    <div className="daty_cont">

                                        <label htmlFor="data_od" className="label_reset">Od</label> 
                                        <TextField className="szukaj_data hasDatepicker" id="data_od" name="data_od" type="date" /> 

                                        <label htmlFor="data_do" className="label_reset">Do</label> 
                                        <TextField className="szukaj_data hasDatepicker" id="data_do" name="data_do" type="date" />

                                    </div>
                            </div>

                            <div className="szukaj_segment mtb10 ">
                                <input type="submit" className="buttonWhite"  value="Szukaj" />
                            </div>

                        </div>
                    :''}

                    <div className="searchDetails tar colorAAA">
                        <a className="" onClick={()=>{ setDetailedSearch( !detailedSearch )}} > {detailedSearch? 'Ukryj w': 'W'}yszukiwanie zaawansowane</a>
                    </div>
                        
                </form>    
            </div>
            
        </header>
    );
    
    
}

export default Header;